import * as React from 'react';
import accessIndexData from '../../data/access/index.json';
import {
  CdnImage,
  DefaultCollapsibleMarkdownSection,
  ExternalLink,
  FlexRow,
  InformationLayout,
} from '../../components';
import { Button } from 'reactstrap';

export const AccessIndexPageInternal = ({ mainMarkdown, header, mobile, online, telephone, lostCard }: typeof accessIndexData) => {
  return (
    <InformationLayout title={header} mainMarkdown={mainMarkdown} header={header}>
      <div className="child-spacing-y-3">
        <DefaultCollapsibleMarkdownSection {...mobile}>
          <FlexRow>
            <div className="child-spacing-x-1">
              <ExternalLink href="https://play.google.com/store/apps/details?id=com.amimembernet.ahcu2&hl=en_US&gl=US">
                <CdnImage style={{ width: 120 }} src="/images/google-play.svg" alt="Google Play Logo" />
              </ExternalLink>
              <ExternalLink href="https://apps.apple.com/ch/app/archer-heights-member-net/id1131408937?l=en">
                <CdnImage style={{ width: 120 }} src="/images/app-store.svg" alt="Apple App Store Logo" />
              </ExternalLink>
            </div>
          </FlexRow>
        </DefaultCollapsibleMarkdownSection>
        <DefaultCollapsibleMarkdownSection {...online}>
          <div>
            <Button color="primary" tag={ExternalLink} href="https://amimembernet.com/ahcu/login">
              Go to Online Banking
            </Button>
          </div>
        </DefaultCollapsibleMarkdownSection>
        <DefaultCollapsibleMarkdownSection {...telephone} />
        <DefaultCollapsibleMarkdownSection {...lostCard} />
      </div>
    </InformationLayout>
  );
};

export default () => <AccessIndexPageInternal {...accessIndexData} />;
